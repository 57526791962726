import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/seo'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Button from '../components/common/button';
import locationIcon from '../images/icons/location.svg'
import { useState } from 'react';

const Contact = () => {
  const {t} = useTranslation();

  const [ subject, setSubject ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ success, setSuccess ] = useState(0);
  
  const handleSubmitForm = async () => {

    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('email', email);
    formData.append('message', message);

    await fetch('https://makronline.com/api/contact_email', {
      method: 'POST',
      body: formData
    });
    setSuccess(1)
  }
  
  return (
    <Layout>
      <Seo title={t("contactTitle")} />
      <div className="container contact-container pt-nav-ghost">
        <h1 className="title-md contain-margin">{t("contactTitle")}</h1>
        <div className="flex">
          <div className="w-1/2 ph-hidden">
            <div className="unc-line"></div>
            <div className="contact-info">
              <img src={locationIcon} alt="location" />
              <div className="flex-1">
                <p className="contact-mid-title">{t("contactLocation")}</p>
                <p className="contact-mid-desc">USA, Delaware</p>
              </div>
            </div>
          </div>
          <div className="w-1/2 ph-full">
            {success === 0 ? 
              <div className="contact-form">
                <div className="flex flex-col w-full mb-input">
                  <p className="input-label">{t("contactFormName")}</p>
                  <input onChange={e => setSubject(e.target.value)} className="input" placeholder={t("contactFormNamePh")} type="text" />
                </div>
                <div className="flex flex-col w-full mb-input">
                  <p className="input-label">{t("contactFormEmail")}</p>
                  <input onChange={e => setEmail(e.target.value)} className="input" placeholder={t("contactFormEmailPh")} type="text" />
                </div>
                <div className="flex flex-col w-full mb-input">
                  <p className="input-label">{t("contactFormMessage")}</p>
                  <textarea onChange={e => setMessage(e.target.value)} rows={10} className="input" placeholder={t("contactFormMessagePh")} type="text" />
                </div>
                <div className="flex flex-col w-full">
                  <Button onClick={handleSubmitForm} type="form">{t("contactFormSend")}</Button>
                </div>
              </div>:
              <div className="contact-form">
                <h2>{t("contactFormSuccessDesc")}</h2>
              </div>
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`